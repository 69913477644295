import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';

import { OffersService } from '../offers.service';

@Injectable({
  providedIn: 'root',
})
export class OffersGuard extends SyncGuard {
  constructor(protected service: OffersService) {
    super();
  }

  sync(params: Params) {
    return this.service.sync(params['schoolId']);
  }
}
